import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Copy, Check} from "react-feather";


const CopyToClipboardToggle = ({ name, text, onCopyValue, mostRecentlyCopied, placement="right" }) => {
    const [clicked, setClicked] = useState(false);
    const handleClick = () => {
      setClicked(true);
      onCopyValue(name)
    }
    const reset = () => setClicked(false);
  
    return (
      <>
        <OverlayTrigger
            triggers="[hover,focus]"
            placement={placement}
            overlay={<Tooltip >{clicked && (mostRecentlyCopied == null || mostRecentlyCopied === name) ? "Copied!" : "Copy to clipboard"}</Tooltip>}
          >
            {clicked && (mostRecentlyCopied == null || mostRecentlyCopied === name) ? <Check className="text-success" onClick={() => {reset()}} style={{'height': '16px', 'width': '16px'}}/> : <Copy cursor="pointer" onClick={() => {navigator.clipboard.writeText(text); handleClick()}} style={{'height': '16px', 'width': '16px'}}/>}
        </OverlayTrigger>
      </>
    );
  }

export default CopyToClipboardToggle;