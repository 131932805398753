import React, { useState, useContext, useEffect } from "react";
import { Button, Spinner, Modal } from "react-bootstrap";
import NotyfContext from "../contexts/NotyfContext";

import authenticatedFetch from "../utils/fetch"

import CopyToClipboardToggle from "./CopyToClipboardToggle";

import { ExternalLink } from "react-feather";

const EmailLinkifier = ({ text }) => {
  const [mostRecentlyCopied, setMostRecentlyCopied] = useState(null);

  // Regular expression to match email addresses and URLs
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b|\bhttps?:\/\/[^\s/$.?#].[^\s]*\b/g;

  // Split the text into parts with email matches and non-email text
  const parts = text.split(emailRegex);

  // Find all matches of email addresses in the text
  const emails = text.match(emailRegex);

  const handleCopyValue = (name) => {
    setMostRecentlyCopied(name);
  }

  return (
    <span style={{wordWrap: "break-word"}}>
      {parts.flatMap((part, index) => {
        // Split part further by newline characters
        const lines = part.split('\n');

        // Map over each line and add <br> where necessary
        return lines.flatMap((line, lineIndex) => [
          <React.Fragment key={`line-${index}-${lineIndex}`}>{line}</React.Fragment>,
          lineIndex < lines.length - 1 && <br key={`br-${index}-${lineIndex}`} />,
        ]).concat(
          // Add the email link if this is not the last part
          emails && emails[index] && emails[index].indexOf('http') < 0 ? (
            <span key={`email-${index}`}>
              <a href="#" onClick={(e) => {e.preventDefault()}}>
                {emails[index]}
              </a>
              &nbsp;
              <CopyToClipboardToggle mostRecentlyCopied={mostRecentlyCopied} name={emails[index]} text={emails[index]} onCopyValue={handleCopyValue} placement="bottom"/>
              &nbsp;
            </span>
          ) : (
            emails && emails[index] && emails[index].indexOf('http') >= 0 ? (
              <span key={`email-${index}`}>
                <a href={emails[index]} target="_blank">
                  {emails[index]}&nbsp;<ExternalLink style={{'height': '14px', 'width': '14px', verticalAlign: "baseline"}}/>
                </a>
              </span>
          ) : null)
        );
      })}
    </span>
  );
};


const TaskAdditionalInstructionsModal = ({ show, onClose, id, category, entityName }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [instructions, setInstructions] = useState("");
    const [attachments, setAttachments] = useState([]);

    const notyf = useContext(NotyfContext);

    useEffect(() => {
      if (show)
      {
        authenticatedFetch({
          path: "task/additionalInstructions/" + id,
          additionalHeaders: {"taskId": id},
          successHandler: function(result) {
            setIsLoaded(true);
            setInstructions(result.instructions);
            setAttachments(result.attachments);
          },
          errorHandler: function(error) {
            notyf.open({type: "error", message: "Something went wrong; please reload the page", duration: 5000, dismissable: true, ripple: true});
          }
        });
      }
      else
      {
        setIsLoaded(false);
        setInstructions("");
        setAttachments([]);
      }
    }, [id, show])
  
    const handleClose = () => {
      onClose();
    }
  
    const openAttachment = (e, url) => {
      e.preventDefault();
      window.open(url);
    }
  
    let attachmentList = attachments && attachments.length > 0 && attachments.map((attachment, index) => {
      return <li key={index}><a href="#" onClick={(e) => openAttachment(e, attachment.url)}>{attachment.filename}</a></li>
    });

    return (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
            <Modal.Title>
              <span style={{fontSize: "0.9em", fontWeight: "normal"}}>{category.label} Instructions for <b>{entityName}</b></span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { !isLoaded ?
            <Spinner animation="border" size="sm" variant="primary" style={{marginLeft: "35px"}}/> :
            <>
            {instructions && instructions !== '' && <p><b>Instructions:</b><br /><EmailLinkifier text={instructions} /></p>}
            {attachments && attachments.length > 0 &&
            <>
              <b>Associated Forms:</b>
              <ul>
                {attachmentList}
              </ul>
            </>
            }
            </>
            }
          </Modal.Body>
          <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
            <Button onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
    );
  }
  
  
  export default TaskAdditionalInstructionsModal;