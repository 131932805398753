import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import TaskModal from "../utils/TaskModal";
import LastActivityDisplay from "../utils/LastActivityDisplay";


const TaskListRow = ({row, showModal, reloadTaskLists}) => {
  const [show, setShow] = useState(showModal);

  if (showModal)
  {
    // Clear the task param... note that this will wipe any other url params too; and gets called every time the task is opened until the page is reloaded
    window.history.pushState({}, document.title, window.location.pathname);
  }
  
  const handleShow =  (cellId) => 
  {
    if (cellId === "otherActions")
    {
      return;
    }

    setShow(true);
  }

  const handleCancel = () => {
    setShow(false);
  }

  const handleSave = () => {
    setShow(false);
    reloadTaskLists();
  }

  const handleDelete = () => {
    setShow(false);
    reloadTaskLists();
  }

  return (
    <>         
      <tr {...row.getRowProps()} style={{cursor: "pointer"}}>
        {row.cells.map((cell) => {
          if (cell.column.key === "otherActions")
          {
            return (
              <td style={{cursor:"auto"}} onClick={(e) => handleShow(cell.column.key)} className={(cell.column.key === "content" || cell.column.key === "created" || cell.column.key === "createdBy" || cell.column.key === "toNumber") && row.id.indexOf(".") > 0 ? "table-secondary" : ""} {...cell.getCellProps()}>
                  {cell.render("Cell")}
              </td>
            );
          }

          return (
            <OverlayTrigger
              key={cell.column.key}
              triggers="[hover,focus]"
              placement="bottom"
              overlay={<Popover><Popover.Body><LastActivityDisplay task={row.original.task}></LastActivityDisplay></Popover.Body></Popover>}
            >
              <td onClick={(e) => handleShow(cell.column.key)} className={(cell.column.key === "content" || cell.column.key === "created" || cell.column.key === "createdBy" || cell.column.key === "toNumber") && row.id.indexOf(".") > 0 ? "table-secondary" : ""} {...cell.getCellProps()}>
                {cell.render("Cell")}
              </td>
              </OverlayTrigger>
          );
        })}
      </tr>
      <TaskModal key={row.original.task.id} show={show} onCancel={handleCancel} onSave={handleSave} onDelete={handleDelete} id={row.original.task.id} category={row.original.task.category.value} practiceId={row.original.task.practiceId} carrierId={row.original.task.carrierId} providerId={row.original.task.providerIsDeleted ? 0 : row.original.task.providerId} hubId={row.original.task.hubId} facilityId={row.original.task.facilityId} other={row.original.task.other} referenceNumber={row.original.task.referenceNumber} assignees={row.original.task.assignees} status={row.original.task.status} due={row.original.task.due} deadline={row.original.task.deadline} progress={row.original.task.progress} archived={row.original.task.archived} activityList={row.original.task.activity}/>
    </>
    )
}

export default TaskListRow;