
import { Button, Modal } from "react-bootstrap";

import DocumentList from "./DocumentList";


const DocumentManagementModal = ({ show, onClose, entityId, entityType, entityName}) => {

  return (
      <Modal show={show} onHide={onClose} dialogClassName={"modal-850"}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Documents for <b>{entityName}</b></span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DocumentList entityId={entityId} entityType={entityType} canEdit={true} isRevenueTeamSpanningFile={true}/>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
          <Button onClick={onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  
  export default DocumentManagementModal;