const rawDocumentCategories =[
    {"value":"", "label":""},
    {"value": "ACLS", "label":"ACLS"},
    {"value": "BLS", "label":"BLS"},
    {"value": "BOARD_CERTIFICATION", "label":"Board Certification"},
    {"value": "CASE_LOG", "label":"Case Log"},
    {"value": "CME", "label":"CME"},
    {"value": "COLLABORATIVE_PLAN", "label":"Collaborative Plan"},
    {"value": "COLOR_PHOTO", "label":"Color Photo"},
    {"value": "CONTRACT", "label":"Contract"},
    {"value": "CONTRACT_CUSTOM", "label":"Contract - Custom"},
    {"value": "CONTRACT_STANDARD", "label":"Contract - Standard"},
    {"value": "CONTROLLED_DANGEROUS_SUBSTANCE", "label":"CDS"},
    {"value": "CREDENTIALING", "label":"Credentialing"},
    {"value": "CV", "label":"CV"},
    {"value": "DD214", "label":"DD214"},
    {"value": "DEA", "label":"DEA"},
    {"value": "DRIVERS_LICENSE", "label":"Driver's License"},
    {"value": "ECFMG_CERTIFICATE", "label":"ECFMG Certificate"},
    {"value": "EDUCATION_TRAINING", "label":"Education & Training"},
    {"value": "FPPE", "label":"FPPE"},
    {"value": "IMMUNIZATION", "label":"Immunization"},
    {"value": "MALPRACTICE_INSURANCE", "label":"Malpractice Ins."},
    {"value": "LICENSE", "label":"Med License"},
    {"value": "ONBOARDING_AGREEMENT", "label":"Onboarding Agmt."},
    {"value": "OTHER", "label":"Other"},
    {"value": "PALS", "label":"PALS"},
    {"value": "PRIVILEGES", "label":"Privileges"},
    {"value": "W9", "label":"W-9"},
    {"value": "INITIAL_ENROLLMENT", "label":"Initial Enrollment"},
    {"value": "RE_ENROLLMENT", "label":"Re-enrollment"},
  ];

const getRawDocumentCategories = (isH3Admin, authorities, isH3AdminRevenueTeamFile=false, isRevenueTeamSpanningFacilityFile=false, isRevenueTeamSpanningCarrierFile=false) => {
    if (isH3AdminRevenueTeamFile) {
        return rawDocumentCategories.filter((category) => {
            return category.value === "" || category.value === 'CONTRACT_CUSTOM' || category.value === 'CONTRACT_STANDARD';
        });
    }

    if (isRevenueTeamSpanningFacilityFile || isRevenueTeamSpanningCarrierFile) {
        return rawDocumentCategories.filter((category) => {
            return category.value === "" || category.value === 'INITIAL_ENROLLMENT' || category.value === 'RE_ENROLLMENT';
        });
    }

    if (isH3Admin || authorities.includes("PROVIDER_SENSITIVE_DETAILS_VIEW") || authorities.includes("PROVIDER_SENSITIVE_DETAILS_EDIT")) {
        return rawDocumentCategories.filter((category) => {
            return category.value !== 'CONTRACT_CUSTOM' && category.value !== 'CONTRACT_STANDARD' && category.value !== "INITIAL_ENROLLMENT" && category.value !== "RE_ENROLLMENT";
        });
    }

    return rawDocumentCategories.filter((category) => {
        return category.value !== 'DRIVERS_LICENSE' && category.value !== 'CONTRACT_CUSTOM' && category.value !== 'CONTRACT_STANDARD' && category.value !== "INITIAL_ENROLLMENT" && category.value !== "RE_ENROLLMENT";
    });
}

export default getRawDocumentCategories;